import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { DRAWER_ANIMATE_DURATION } from 'ng-zorro-antd/drawer';
import { timer } from 'rxjs';

import { StoreService } from '@services/store.service';
import { BaseComponent } from '@base/base.component';

@Component({ template: '' })
export class BaseDrawerComponent extends BaseComponent {
    @Output() closePanel: EventEmitter<any> = new EventEmitter();

    open: boolean = false;
    working: boolean = false;

    constructor(protected _store?: StoreService, protected _cdr?: ChangeDetectorRef) {
        super(_store);
        timer(DRAWER_ANIMATE_DURATION).subscribe(() => {
            this.open = true;
            this._cdr?.markForCheck();
            this.onPanelOpened();
        });
    }

    close(data?: any, keepOpened = false): void {
        if (!keepOpened) {
            this.open = false;
            this._cdr?.markForCheck();
            timer(DRAWER_ANIMATE_DURATION).subscribe(() => this.onPanelClosed(data));
        }
        else {
            this.onPanelClosed(data);
        }
    }

    onPanelClosed(data?: any): void {
        this.closePanel.emit(data);
        this._cdr?.markForCheck();
    }

    onPanelOpened(): void {}

}
